import React from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  CssBaseline,
  Link,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EmailIcon from '@mui/icons-material/Email';
import { MAIN_COLOR, TITLE } from '../../themes/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: MAIN_COLOR,
    },
    secondary: {
      main: TITLE,
    },
  },
});

const SupportPage: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            gap: 2,
          }}
        >
          <EmailIcon color="primary" sx={{ fontSize: 64 }} />
          <Typography variant="h4" component="h1" gutterBottom>
            サポートが必要ですか？
          </Typography>
          <Typography variant="body1" color="textSecondary">
            私たちはいつでもお手伝いします！以下のフォームに記入するか、直接
            <Link
              href="mailto:apps.hpda@gmail.com"
              color="primary"
              underline="hover"
            >
              {' apps.hpda@gmail.com'}
            </Link>
            にメールでご連絡ください。
          </Typography>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ width: '100%', mt: 2 }}
          >
            <TextField
              fullWidth
              label="お名前"
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              label="メールアドレス"
              variant="outlined"
              margin="normal"
              type="email"
            />
            <TextField
              fullWidth
              label="メッセージ"
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2, width: '100%' }}
            >
              送信
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SupportPage;
