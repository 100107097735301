import React from 'react';
import { Typography, Box, Container } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  return (
    <Container style={{ marginTop: '2rem' }}>
      <Typography variant="h4" gutterBottom>
        プライバシーポリシー
      </Typography>
      <Typography variant="body1">
        本プライバシーポリシーは、2025年1月1日より施行され、「広島県歯科医師会
        会員連絡アプリ」（Hiroshima Prefectural Dental Association –
        Member&apos;s Contact App、以下「HPDAMCA」）に適用されます。
      </Typography>
      <Typography variant="body1">
        HPDAMCAアプリは広島県歯科医師会によって開発されました。
      </Typography>
      <Box marginTop="2rem">
        <Typography variant="h6">1. バージョン</Typography>
        <Typography variant="body1">
          現在のHPDAMCAアプリのバージョンはV1.0.0です。
        </Typography>
      </Box>
      <Box marginTop="2rem">
        <Typography variant="h6">2. 収集される個人情報</Typography>
        <Typography variant="body1">
          (1) 歯科医師情報: メールアドレス、氏名、電話番号、協議会情報。
        </Typography>
        <Typography variant="body1">
          (2) 従業員情報: 歯科医師の従業員リスト。
        </Typography>
      </Box>
      <Box marginTop="2rem">
        <Typography variant="h6">3. 個人情報の利用目的</Typography>
        <Typography variant="body1">
          (1) メールアドレス、氏名、協議会情報: セミナー参加登録のために使用。
        </Typography>
        <Typography variant="body1">
          (2) 電話番号: 災害時に歯科医師に連絡するために使用。
        </Typography>
        <Typography variant="body1">
          (3) 歯科医師の従業員リスト: セミナー参加者のリスト作成のために使用。
        </Typography>
      </Box>
      <Box marginTop="2rem">
        <Typography variant="h6">4. 個人情報の保護</Typography>
        <Typography variant="body1">
          HPDAMCAアプリは、個人情報を第三者と共有しないことをお約束します。
        </Typography>
      </Box>
      <Box marginTop="2rem">
        <Typography variant="h6">5. その他収集される情報</Typography>
        <Typography variant="body1">
          使用デバイス情報: デバイスの種類（Android/iOS）、Firebase Cloud
          Messaging（FCM）のトークン。
        </Typography>
      </Box>
      <Box marginTop="2rem">
        <Typography variant="h6">6. 情報の収集方法</Typography>
        <Typography variant="body1">
          使用デバイス情報:
          広島県歯科医師会から提供されたアカウントでHPDAMCAアプリにログインした際に収集されます。
        </Typography>
      </Box>
      <Box marginTop="2rem">
        <Typography variant="h6">7. 情報の利用目的</Typography>
        <Typography variant="body1">
          (1) 指定された地域の歯科医師のデバイスに通知を送信。
        </Typography>
        <Typography variant="body1">
          (2) 歯科医師がセミナー情報を更新し、緊急時の安全を確保するため。
        </Typography>
      </Box>
      <Box marginTop="2rem">
        <Typography variant="h6">8. 情報の共有および転送</Typography>
        <Typography variant="body1">
          (1) HPDAMCAアプリは個人情報を共有または転送しません。
        </Typography>
        <Typography variant="body1">
          (2) 収集された情報は広島県歯科医師会によってのみ利用されます。
        </Typography>
      </Box>
      <Box marginTop="2rem">
        <Typography variant="h6">9. お問い合わせ</Typography>
        <Typography variant="body1">
          メールアドレス: apps.hpda@gmail.com
        </Typography>
        <Typography variant="body1">電話番号: 082-263-8020</Typography>
        <Typography variant="body1">郵便番号: 〒732-0057</Typography>
        <Typography variant="body1">
          住所: 広島県広島市東区二葉の里三丁目2番4号広島県歯科医師会館6階
        </Typography>
      </Box>
      <Box marginTop="2rem">
        <Typography variant="body1">
          HPDAMCAアプリをダウンロードおよび利用することで、本プライバシーポリシーに同意したものとみなされます。
        </Typography>
        <Typography variant="body1">
          ご質問、ご意見、またはご懸念がございましたら、上記の「お問い合わせ」セクションをご利用ください。
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
